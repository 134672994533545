import { t } from 'bv-i18n';

export const TERMS_AND_CONDITIONS_ID = 'terms_and_conditions';
const PRIVACY_POLICY_ID = 'privacy_policy';
const RESPONSIBLE_GAMBLING_ID = 'responsible_gambling';

const msgToUrlMap = {
  1: TERMS_AND_CONDITIONS_ID,
  2: PRIVACY_POLICY_ID,
  3: RESPONSIBLE_GAMBLING_ID,
};

export const title = () => t('javascript.customer_consent.title');
export const body = () => t('javascript.customer_consent.body_text');

export const consentMessages = () => ({
  1: t('javascript.customer_consent.terms_and_conditions_text'),
  2: t('javascript.customer_consent.privacy_policy_text'),
  3: t('javascript.customer_consent.responsible_gambling_text'),
  [TERMS_AND_CONDITIONS_ID]: t('javascript.customer_consent.terms_and_conditions_text'),
  [PRIVACY_POLICY_ID]: t('javascript.customer_consent.privacy_policy_text'),
  [RESPONSIBLE_GAMBLING_ID]: t('javascript.customer_consent.responsible_gambling_text'),
});

export const consentUrls = (links) => ({
  1: links[TERMS_AND_CONDITIONS_ID],
  2: links[PRIVACY_POLICY_ID],
  3: links[RESPONSIBLE_GAMBLING_ID],
  [TERMS_AND_CONDITIONS_ID]: links[TERMS_AND_CONDITIONS_ID],
  [PRIVACY_POLICY_ID]: links[PRIVACY_POLICY_ID],
  [RESPONSIBLE_GAMBLING_ID]: links[RESPONSIBLE_GAMBLING_ID],
});

const formattedMessages = (msgs) => msgs.map((msg) => consentMessages()[msgToUrlMap[msg]]).join(', ');

export const checkBoxText = (msgs) => `${t('javascript.customer_consent.checkbox_text')} ${formattedMessages(msgs)}`;
