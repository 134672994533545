import { getJSON, postJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

const fetchPageBySlug = (slug) => getJSON(`pages/${slug}`);

const submitCustomerConsent = (messages, termsConditionsVersion) => postJSON(`/security_checks/${locale()}/customer_consent.json`, {
  messages,
  termsConditionsVersion,
  accept: 'true',
});

export default (messages, termsConditionsSlug) => () => fetchPageBySlug(termsConditionsSlug)
  .then((page) => submitCustomerConsent(messages, page.version))
  .catch(() => submitCustomerConsent(messages, null));
