import PropTypes from 'prop-types';
import Links from './components/links_component';
import ConsentModal from './components/consent_modal';
import {
  title, body, checkBoxText, TERMS_AND_CONDITIONS_ID,
} from './helpers';
import submitCustomerConsentData from './api';

const App = (props) => {
  const {
    msgs,
    urls,
    slugs,
    onSecondaryClick,
    secondaryButtonText,
    modalClassName,
  } = props;

  return (
    <ConsentModal
      title={title}
      body={body}
      checkBoxText={checkBoxText(msgs)}
      onPrimaryClick={submitCustomerConsentData(msgs, slugs[TERMS_AND_CONDITIONS_ID])}
      onSecondaryClick={onSecondaryClick}
      secondaryButtonText={secondaryButtonText}
      modalClassName={modalClassName}
    >
      <Links msgs={msgs} urls={urls} />
    </ConsentModal>
  );
};

App.propTypes = {
  msgs: PropTypes.arrayOf(PropTypes.number).isRequired,
  urls: PropTypes.instanceOf(Object).isRequired,
  slugs: PropTypes.instanceOf(Object).isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
  modalClassName: PropTypes.string,
};

App.defaultProps = {
  modalClassName: '',
};

export default App;
