import PropTypes from 'prop-types';
import { consentMessages, consentUrls } from '../helpers';

const LinkFor = ({ urls, msg }) => {
  const title = consentMessages()[msg];
  const url = new URL(consentUrls(urls)[msg], window.location.origin);

  return (
    <a target="_blank" href={url.href} rel="noopener noreferrer">
      {title}
    </a>
  );
};

const Links = ({ msgs, urls }) => (
  <div className="consent-links">
    {msgs.map((msg) => <LinkFor msg={msg} urls={urls} />)}
  </div>
);

Links.propTypes = {
  msgs: PropTypes.arrayOf(PropTypes.number).isRequired,
  urls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LinkFor.propTypes = {
  msg: PropTypes.number.isRequired,
  urls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Links;
